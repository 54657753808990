import React from 'react'
import { BasicSearch } from '../components/search/basicSearch'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { Seo } from '../components/seo/Seo'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { DownloadContextProvider } from '../context/DownloadContext'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

const Hochschulsuche: React.FC = () => {
  const currentSlug = '/hochschulsuche'
  const currentLocale: AppLocale = GenerateLocales.de
  const seoTitle = 'Weiterbildungsangebote deutscher Hochschulen auf hoch & weit!'
  const seoDescription =
    'Finden Sie jetzt heraus, welche deutschen Hochschulen das für Sie passende Weiterbildungsangebot anbieten. Weiterbildung für alle Branchen, Karrierestufen und für jedes Alter.'

  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'EditorialPages' })

  // TODO: redirect to '/search?t=Universities'. @see frontend-web/huw-gatsby/src/pages/weiterbildung-interessentest.tsx

  // see HOW-608, has a special social title and description
  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <DownloadContextProvider>
        <LayoutWrapper
          currentTitle={seoTitle}
          currentLocale={currentLocale}
          currentSlug={currentSlug}
          currentPath={currentPagePath}
          otherLocales={[{ locale: GenerateLocales.en, slug: '/en/higher-educations-institutes-search/' }]}
        >
          <BasicSearch category={'Universities'} />
        </LayoutWrapper>
      </DownloadContextProvider>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/hochschulsuche/`} />
}

export default Hochschulsuche
